import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { Product } from '../../interfaces/product';

interface CreateProductData extends Partial<Product> {
  contributorId?: string | null;
  labels?: string[] | undefined;
  picture?: File;
}

const addProduct = async (product: CreateProductData) => {
  try {
    const { data } = await axiosInstance.post('/products', product, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error creating product:', error);
    throw new Error((error as any).data.message ?? 'Failed to create product');
  }
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (product: CreateProductData) => addProduct(product),
    onSuccess: () => {
      queryClient.invalidateQueries(['products'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
