import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { ILocation } from '../../interfaces/location';

const createLocation = async (location: ILocation) => {
  try {
    const { data } = await axiosInstance.post(`/location`, location);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error editing location:', error);
    throw new Error((error as any).data.message ?? 'Failed to edit location');
  }
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (location: ILocation) => createLocation(location),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
